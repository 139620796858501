import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { StaticImage } from "gatsby-plugin-image";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import ImagesTop from "../../platform/huey-mobile/ImagesTop";
export const _frontmatter = {
  "title": "Huey H₂O"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout className="page huey-h2o-page" mdxType="Layout">
      <Seo title={props.pageContext.frontmatter.title} mdxType="Seo" />
      <div className="col-10 offset-1">
        <h1>{`Huey H₂O`}</h1>
        <p>{`Huey H₂O is a magnetic pulse sensor and transmitter compatible with pulse output water meters eg. Itron DT8.`}</p>
        <p>{`Huey H₂O provides water usage data with realtime updates, viewable via `}<a parentName="p" {...{
            "href": "/platform/huey-mobile"
          }}>{`Huey Mobile`}</a>{`.`}</p>
        <ul>
          <li parentName="ul">{`Data update frequency between 5 minutes and 24 hours.`}</li>
          <li parentName="ul">{`Simple installation - just slide the unit into the tabs on your water meter. No plumber required.`}</li>
          <li parentName="ul">{`Simple pairing - just enter or scan the serial number into the mobile app.`}</li>
          <li parentName="ul">{`Includes a security screw for preventing theft. The sensor is also useless to thieves.`}</li>
          <li parentName="ul">{`Fully sealed waterproof unit including fixed long life battery.`}</li>
          <li parentName="ul">{`Battery life is typically 5+ years, depending on data update frequency and distance from gateway.`}</li>
          <li parentName="ul">{`Uses Helium IoT wireless network and potentially other LoRaWAN networks (on request).`}</li>
          <li parentName="ul">{`Does not use WiFi. No risk to your WiFi network security. No network configuration required.`}</li>
          <li parentName="ul">{`Does not require power or network from the property - great for property managers.`}</li>
        </ul>
        <h2>{`Requirements`}</h2>
        <ol>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/platform/helium"
            }}>{`Helium`}</a>{` network coverage, now available in most major cities globally. See our `}<a parentName="li" {...{
              "href": "/platform/helium"
            }}>{`Helium Support`}</a>{` page.`}</li>
          <li parentName="ol">{`A compatible utility water meter (Itron "Cyble" eg. TD8 model). See our `}<a parentName="li" {...{
              "href": "/products/huey-h2o/support"
            }}>{`Support`}</a>{` page.`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/platform/huey-mobile"
            }}>{`Huey Mobile`}</a>{` app installed and paired with Huey H₂O.`}</li>
        </ol>
        <br />
        <div className="w-50 mx-auto">
          <ImagesTop mdxType="ImagesTop" />
        </div>
        <br />
        <br />
      </div>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      